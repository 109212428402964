<br/>
<br/>
<br/>
<br/>
<br/>
<section>
<div class="container">
  <div class="row">
    <div class="container" *ngFor="let item of posts | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
      <!-- content here -->
      <div class="col-sm">
      <h3>{{item.title}}</h3>
      <br/>
      <p [innerHTML]="item.content"></p>
      <hr>
      </div>
  </div>
</div>
<div class="col-12 justify-content-center">
    <ngb-pagination
  [(page)]="page"
  [pageSize]="pageSize"
  [collectionSize]="posts.length"></ngb-pagination>
  </div>
</div>
</section>
