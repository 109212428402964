import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { IndexComponent } from './posts/index/index.component';
import { RequestComponent } from './posts/request/request.component';
import { RetailComponent } from './posts/retail/retail.component';
import { BuildingComponent } from './posts/building/building.component';
import { PostconstructionComponent } from './posts/postconstruction/postconstruction.component';
import { PowerwashingComponent } from './powerwashing/powerwashing.component'
import { CarpetcleaningComponent } from './carpetcleaning/carpetcleaning.component'
import { BlogComponent } from './blog/blog.component';
import { HotelComponent } from './posts/hotel/hotel.component';


const routes: Routes = [
  { path: '', component: IndexComponent },
  { path: 'request', component: RequestComponent },
  { path: 'hotel', component: HotelComponent },
  { path: 'retail', component: RetailComponent },
  { path: 'building', component: BuildingComponent },
  { path: 'postconstruction', component: PostconstructionComponent },
  { path: 'powerwashing', component: PowerwashingComponent },
  { path: 'carpetcleaning', component: CarpetcleaningComponent },
  { path: 'blog', component: BlogComponent },
  { path: '**',   redirectTo: ''}
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  onSameUrlNavigation: 'reload'
};

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
