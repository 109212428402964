<br/>
<br/>
<br/>
<br/>
<br/>
<section>
    <div class="container">
        <h3>CONTACT US NOW</h3>
        <div class="row">
          <div class="col-sm">
              <div class="card-body">
                  <form name="form" (ngSubmit)="f.form.valid && onSubmit(f)" #f="ngForm" novalidate>
                      <div class="form-group">
                        <label for="name">Name</label>
                        <input type="text" class="form-control" name="name" id="name" [(ngModel)]="model.name" #name="ngModel" [ngClass]="{'is-invalid': f.submitted && name.invalid}" required>
                        <div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                            <div *ngIf="name?.errors.required">Name is required</div>
                        </div>
                      </div>
                      <div class="form-group">
                          <label for="exampleInputEmail1">Email address</label>
                          <input type="text" name="email" class="form-control" [(ngModel)]="model.email" #email="ngModel" [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email>
                          <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
                              <div *ngIf="email?.errors.required">Email is required</div>
                              <div *ngIf="email?.errors.email">Email must be a valid email address</div>
                          </div>
                        </div>
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">Message</label>
                            <textarea class="form-control noresize" name="message" id="exampleFormControlTextarea1" rows="5" [(ngModel)]="model.message" #message="ngModel" [ngClass]="{ 'is-invalid': f.submitted && message.invalid }" required></textarea>
                            <div *ngIf="f.submitted && name.invalid" class="invalid-feedback">
                                <div *ngIf="message?.errors.required">Message is required</div>
                            </div>
                          </div>
                      <button type="submit" class="btn btn-success">Submit</button>
                      <br/>
                      <br/>
                      <div class="alert alert-success" role="alert" [hidden]="isHidden">
                        <p>Message sent successfully!</p>
                        </div>
                      <br/>
                    </form>
                </div>
          </div>
          <div class="col-sm">
                <br/>
                <p>With an unwavering commitment to quality and customer-service, you can rest assured that the professional team at Wunderbar Maintenance, you will always get dependable results you can count on the first time, and every time. </p>
                <p>With the experience gained from servicing a diverse client-base, from but not limited to offices, to post-construction cleaning. We also offer power-washing services to both residential and commercial properties. </p>
                <p>Don't hesitate now, contact us for a free quote regarding your cleaning needs.</p>
          </div>
        </div>
  </div>
</section>
