import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  images = [62, 83, 466, 965, 982, 1043, 738].map((n) => `https://picsum.photos/id/${n}/900/500`);
  comments = [{reviewer: "Jose Gonzalez, Office Manager",
               review: "We hired Wunderbar after having issues with our previous cleaning contractor. After meeting with Prasanthan, and outlining our needs, he put together a proposal and it's been smooth-sailing ever since. I can truly recommend Wunderbar!"
              }, { reviewer: "Amritpal Singh, Pizza Pizza",
                review: "I've been dealing with Wunderbar for more than a year now, and they're truly professionals. They've always addressed my concerns in a timely manner. It saves a lot of hassle to not have to worry about the cleanliness of the restaurant, so I can focus more on my customers."}
                , { reviewer: "Mark Schwartz, Homeowner",
                  review: "I hired Wunderbar Maintenance for their powerwashing services. They came in, explained what they were going to do, and did everything in a timely and neat manner. Highly recommend these guys, they made my driveway, patio, and fences look brand new again!"}]

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;

  constructor() { }

  ngOnInit(): void {
  }

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }


}
