import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-postconstruction',
  templateUrl: './postconstruction.component.html',
  styleUrls: ['./postconstruction.component.css']
})
export class PostconstructionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
