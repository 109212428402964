<br/>
<br/>
<br/>
<br/>
<div class="container-fluid padding-zero video">
  <div class="row no-gutters video">
  <div class="embed-responsive embed-responsive-16by9">
    <video width="100vw" autoplay loop muted playsinline onClick="this.paused ? this.play() : this.pause();" oncanplay="this.play()" onloadedmetadata="this.muted = true">
      <source type="video/mp4" src="/assets/videos/video0.mp4">
    </video>
</div>
</div>
</div>
<br/>
<section>
<div class="container">
        <div class="row">
          <div class="col-md-6">
            <h3><a class="aboutus" id ="aboutus" name="aboutus">About Us</a></h3>
              <br/>
              <p>Wunderbar Maintenance adopts outcome-based janitorial programs in order to meet each of our client's needs. By carefully examining our clients needs, we create a customized regime that tailors commercial cleaning products, services, and schedules accordingly.</p>
              <p>With clients such as King Blue Hotel Toronto, Sandman (Mississauga and Ottawa), Avid Hotel, we have extensive knowledge and experience when it comes to the upkeep of hotels—from getting you the right supervisor to handling maintenance, and everything in between, Wunderbar is more than capable of assisting of you.</p>
              <p>Our team of professionals will always be up to the task, whether it’s taking over maintenance of your hotel or finding the right janitorial solution for your commercial property, you can always count on us.</p>
              <img class="d-inline" id="side" src="./assets/images/cleaners.jpg">
          </div>
          <div class="col-md-6">
            <h3><span class="badge badge-primary">Our Mission</span></h3>
            <ul class="list-group list-group-flush">
                  <li class="list-group-item list-group-side">Forge valued partnerships with staff, clients, stakeholders and work towards shared objectives </li>
                  <hr>
            </ul>
            <h3><span class="badge badge-primary">Our Vision</span></h3>
            <ul class="list-group list-group-flush">
                  <li class="list-group-item list-group-side">To become one of the leading janitorial services in Canada by postively impacting the quality of life at our clients' workplaces in a professional and safe manner</li>
                  <hr>
            </ul>
            <h3><span class="badge badge-primary">Our Values</span></h3>
            <ul class="list-group list-group-flush">
                  <li class="list-group-item list-group-side">To be efficient as possible without sacrificing quality of our work</li>
                  <li class="list-group-item list-group-side">To be cost-effective for our clients while maximizing the quality of work done</li>
                  <li class="list-group-item list-group-side">To maintain a high-standard of cleanliness</li>
                  <hr>
            </ul>
          </div>
        </div>
  </div>
  <br />
  <br />
  <br/>
</section>
<section class="service-locations">
<div class="container-fluid">
  <div class="row">
    <div class="col-md-6 blue">
      <div class="container-left-half">
        <div class="row">
          <br/>
          <div class="col-md-12">
            <h3>SERVICES OFFERED</h3>
            <div class="row">
              <div class="col-sm-6">
                <ul>
                  <li class="icon"><p class="icon-text">Office Cleaning</p></li>
                  <li class="icon"><p class="icon-text">Retail Cleaning</p></li>
                  <li class="icon"><p class="icon-text">Warehouse Cleaning</p></li>
                  <li class="icon"><p class="icon-text">Institutional Cleaning</p></li>
                  <li class="icon"><p class="icon-text">Kitchen Equipment Cleaning</p></li>
                  <li class="icon"><p class="icon-text">Hotel Cleaning & Maintenance</p></li>
                  <li class="icon"><p class="icon-text">Industrial Cleaning</p></li>
                </ul>
              </div>
              <div class="col-sm-6 ">
                <ul>
                  <li class="icon"><p class="icon-text">Conference Center Cleaning</p></li>
                  <li class="icon"><p class="icon-text">Community Center Cleaning</p></li>
                  <li class="icon"><p class="icon-text">Restaurant Cleaning</p></li>
                  <li class="icon"><p class="icon-text">Post-Construction Cleaning</p></li>
                  <li class="icon"><p class="icon-text">Powerwashing Services</p></li>
                  <li class="icon"><p class="icon-text">And much more</p></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 ">
      <div class="container-right-half">
        <div class="row">
          <div class="col-md-12">
            <h3>SERVICE LOCATIONS</h3>
            <div class="row">
              <div class="col-sm-6">
                <ul>
                  <li class="side-icon"><p class="side-icon-text">GTA</p></li>
                  <li class="side-icon"><p class="side-icon-text">Mississauga</p></li>
                  <li class="side-icon"><p class="side-icon-text">Brampton</p></li>
                  <li class="side-icon"><p class="side-icon-text">Caledon</p></li>
                  <li class="side-icon"><p class="side-icon-text">Halton Hills</p></li>
                  <li class="side-icon"><p class="side-icon-text">Markham</p></li>
                  <li class="side-icon"><p class="side-icon-text">Alberta</p></li>
                </ul>
              </div>
              <div class="col-sm-6">
                <ul>
                  <li class="side-icon"><p class="side-icon-text">Vaughan</p></li>
                  <li class="side-icon"><p class="side-icon-text">Pickering</p></li>
                  <li class="side-icon"><p class="side-icon-text">Ajax</p></li>
                  <li class="side-icon"><p class="side-icon-text">Oshawa</p></li>
                  <li class="side-icon"><p class="side-icon-text">Richmond Hill</p></li>
                  <li class="side-icon"><p class="side-icon-text">Ottawa</p></li>
                  <li class="side-icon"><p class="side-icon-text">Many more cities</p></li>
                </ul>
              </div>
            </div>
          </div>
                </div>
      </div>
    </div>
  </div>
</div>
</section>
<br/>

<section>
  <div class="container">
    <div class="row">
      <div class="col-lg">
        <h3>Here's what our clients have to say!</h3>
  <ngb-carousel #carousel [interval]="3000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus" (slide)="onSlide($event)">
    <ng-template ngbSlide *ngFor="let comment of comments; index as i">
      <div class="carousel-caption">
        <p>"{{comment.review}}"</p>
        <br/><br/>
        <h3>- {{comment.reviewer}}</h3>
        <br/><br/>
      </div>
      <div class="picsum-img-wrapper">
        <img src="/assets/images/0.jpg">
      </div>
    </ng-template>
  </ngb-carousel>
  </div>
</div>
</div>
</section>
