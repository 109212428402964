<br/>
<br/>
<br/>
<br/>
<br/>
<section>
    <div class="container">
        <div class="row">
          <div class="col-sm">
              <h3>CARPET CLEANING</h3>
              <br/>
               <p>Carpets are prone to attracting dirt, whether it is from everyday wear or from the accidental spill, it can quickly go from looking pristine to dirty. Wunderbar knows this, and we have the expertise to bring your carpets back to looking pristine and well-maintained. </p>
               <br/>
               <p> Our services will be catered to your facility and its needs. Get in touch with us now for a quote!</p>
              <a class="btn btn-primary" [routerLink]="['/request']">Request a quote</a>
              <p></p>
              <br/>
          </div>
          <div class="col-sm">
              <img class="d-inline" id="side" src="./assets/images/carpet.jpg">
          </div>
        </div>
  </div>
</section>
