<nav class="navbar navbar-expand-md fixed-top navbar-light bg-light" id="header">
  <a href="#" class="navbar-brand" ><img id="logo" src="./assets/images/logo.png"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item dropdown active">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Cleaning Services
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li data-toggle="collapse" data-target=".navbar-collapse.show"><a class="dropdown-item" data-toggle="collapse" [routerLink]="['/hotel']" >Hotel Cleaning & Maintenance</a></li>
          <li data-toggle="collapse" data-target=".navbar-collapse.show"><a class="dropdown-item" data-toggle="collapse" [routerLink]="['/retail']" >Retail, Office, Restaurant Cleaning</a></li>
          <li data-toggle="collapse" data-target=".navbar-collapse.show"><a class="dropdown-item" data-toggle="collapse" [routerLink]="['/building']" >Building Cleaning & Maintenance</a></li>
          <div class="dropdown-divider"></div>
          <li data-toggle="collapse" data-target=".navbar-collapse.show"><a class="dropdown-item" data-toggle="collapse" [routerLink]="['/postconstruction']" >Post-Construction Cleaning</a></li>
        </div>
      </li>
      <li class="nav-item dropdown active">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Maintaining Services
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <li data-toggle="collapse" data-target=".navbar-collapse.show"><a class="dropdown-item" data-toggle="collapse" [routerLink]="['/powerwashing']">Power Washing</a></li>
          <li data-toggle="collapse" data-target=".navbar-collapse.show"><a class="dropdown-item" data-toggle="collapse" [routerLink]="['/carpetcleaning']">Carpet Cleaning</a></li>
        </div>
      </li>
      <li class="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
        <a class="nav-link" data-toggle="collapse" [routerLink]="['/blog']">Blog</a>
    </li>
      <li class="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" data-toggle="collapse" [routerLink]="['/']" fragment="aboutus">About Us</a>
      </li>
      <li class="nav-item active" data-toggle="collapse" data-target=".navbar-collapse.show">
          <a class="nav-link" data-toggle="collapse" [routerLink]="['/request']">Request a quote</a>
        </li>
    </ul>
    <form class="form-inline">
        <a class="btn btn-info" href="tel:647-261-1437"><i class="fa fa-phone" style="font-size:18px;color:white"></i>647-261-1437</a>
    </form>
  </div>
</nav>
