<br/>
<br/>
<br/>
<br/>
<br/>
<section>
    <div class="container">
        <div class="row">
          <div class="col-sm">
              <h3>POST-CONSTRUCTION & <br/>POST-RENOVATION CLEANING</h3>
              <br/>
               <p>At Wunderbar, we know how messy construction and renovation can get. We'll work to get your facility back to being spick and span in no time. Through a tried and tested regime, we will ensure your facility is disinfected and dust-free.</p>
               <p>We will work with you to create a plan to ensure that the areas you need to be absolutely certain is clean, are cleaned to the highest standards of Wunderbar.</p>
               <p>That's why we provide services including, but not limited to: </p>
               <br/>
               <ul class="list-group list-group-flush">
                  <li class="list-group-item">Waste Management</li>
                  <li class="list-group-item">Deep Cleaning</li>
                  <li class="list-group-item">Thorough dusting of all surfaces and furnitures</li>
                  <li class="list-group-item">Kitchen Appliances</li>
                  <li class="list-group-item">Window cleaning</li>
                  <li class="list-group-item">vacuuming</li>
                  <li class="list-group-item">glass surfaces</li>
                  <li class="list-group-item">Bathroom sanitization</li>
              </ul>
              <br/>
          </div>
          <div class="col-sm">
              <img class="d-inline" id="side" src="./assets/images/house.jpg">
              <br/><br/><br/>
              <img class="d-inline" id="side" src="./assets/images/postconstruction.jpg">
              <p> Our services will be catered to your facility and its needs. Get in touch with us now for a quote!</p>
              <a class="btn btn-primary" [routerLink]="['/request']">Request a quote</a>
              <p></p>
          </div>
        </div>
  </div>
</section>
