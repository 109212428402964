<br/>
<br/>
<br/>
<br/>
<br/>
<section>
    <div class="container">
        <div class="row">
          <div class="col-sm">
              <h3>Office, Retail, Restaurant Cleaning</h3>
              <br/>
              <p>At Wunderbar, we realize how important it is to maintain a high-level of cleanliness to ensure that your facility is sanitary, presentable for both your clients and employees.</p>
              <p>With a carefully customized cleaning package designed to fit your needs, we can clean as frequent as required. Whether you require daily cleaning after-hours, once a week, every other week, or monthly, we will work with you to ensure your facility is spotless.</p>
               <p> With a diverse client-base, we have the experience to tackle your cleaning needs correctly the first time around. We will ensure that your facility is sanitized and cleaned to the highest standards. What does that entail?</p><p>It includes, but is not limited to:</p>
               <br/>
               <ul class="list-group list-group-flush">
                <li class="list-group-item">Waste Management</li>
                <li class="list-group-item">Floor Cleaning and maintenance</li>
                <li class="list-group-item">Dusting</li>
                <li class="list-group-item">Kitchen appliances</li>
                <li class="list-group-item">Window cleaning</li>
                <li class="list-group-item">Vacuuming</li>
                <li class="list-group-item">Glass surfaces</li>
                <li class="list-group-item">Dusting of all surfaces and furniture</li>
                <li class="list-group-item">Washroom Sanitation</li>
              </ul>
              <br/>
               
          </div>
          <div class="col-sm">
              <img class="d-inline" id="side" src="./assets/images/mop.jpg">
              <br/><br/><br/>
              <img class="d-inline" id="side" src="./assets/images/retail.jpg">
              <p> Our services will be catered to your facility and its needs. Get in touch with us now for a quote!</p>
              <a class="btn btn-primary" [routerLink]="['/request']">Request a quote</a>
              <p></p>
          </div>
        </div>
  </div>
</section>
