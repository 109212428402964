<hr>
<footer id="sticky-footer" class="footer mt-auto py-3">
    <div class="container">
      <br/>
      <div class="row">
        <div class="col-md-6">
          <img src="./assets/images/logo.png">
        </div>
        <div class="col-md-6">
          <p>Services <br/>
            <a [routerLink]="['/retail']">Retail, Office, Restaurant Cleaning</a>
            <br/>
            <a [routerLink]="['/building']">Building Cleaning & Maintenance</a>
            <br/>
            <a [routerLink]="['/postconstruction']">Post-Construction Cleaning</a>
          <br/>
        </p>
        </div>
      </div>
      <div class="row">
          <div class="col-md-6">
          </div>
          <div class="col-md-6">
              <p> Contact Us
              <br/>
              <i class="fa fa-map-marker" style="font-size:18px;color:black"></i><a href="#"> 445 Midwest Rd, Unit #2 Scarborough, ON M1P 4Y9</a>
              <br/>
              <i class="fa fa-phone" style="font-size:18px;color:black"></i><a href="tel:647-261-1437"> 647-261-1437</a>
              <br/>
              <i class="fa fa-envelope" style="font-size:18px;color:black"></i><a href="mailto:info@wunderbarmaintenance.com"> info@wunderbarmaintenance.com</a></p>
          </div>
      </div>
      <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center">
            <p class="h6">© 2023 Wunderbar Maintenance</p>
          </div>
        </div>
    </div>
  </footer>
<a  [routerLink]="['/request']" class="float">
  <i class="fa fa-envelope my-float"></i>
</a>
