import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Post } from './post';


@Injectable({
  providedIn: 'root'
})
export class PostService {

  baseUrl = 'https://www.wunderbarmaintenance.com/backend';
  posts: Post[];

  constructor(private http: HttpClient) { }

  getAll(): Observable<Post[]> {
    return this.http.get(`${this.baseUrl}/getPosts.php`).pipe(
      map((res) => {
        console.log(res);
        // tslint:disable-next-line: no-string-literal
        this.posts = res['data'];
        return this.posts;

      }),
      catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
      console.log(error);
      // return an observable with a user friendly message
      return throwError('Error! something went wrong.');
    }
  }

