import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { AppRoutingModule } from './app-routing.module';
import { IndexComponent } from './posts/index/index.component';
import { FooterComponent } from './footer/footer.component';
import { BuildingComponent } from './posts/building/building.component';
import { PostconstructionComponent } from './posts/postconstruction/postconstruction.component';
import { RetailComponent } from './posts/retail/retail.component';
import { RequestComponent } from './posts/request/request.component';
import { BlogComponent } from './blog/blog.component';
import { PowerwashingComponent } from './powerwashing/powerwashing.component';
import { CarpetcleaningComponent } from './carpetcleaning/carpetcleaning.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { HotelComponent } from './posts/hotel/hotel.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    IndexComponent,
    FooterComponent,
    BuildingComponent,
    PostconstructionComponent,
    RetailComponent,
    RequestComponent,
    BlogComponent,
    PowerwashingComponent,
    CarpetcleaningComponent,
    HotelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
