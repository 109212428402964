import { Component, OnInit } from '@angular/core';
import { Mail } from 'src/app/mail.model';
import { ConnectionService } from 'src/app/connection.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  isHidden = true;
  model: Mail = {
    name: '',
    email: '',
    message: ''
  };
  constructor(private connectionService: ConnectionService) { }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm) {
    this.connectionService.sendMessage(this.model).subscribe(() => {
      this.isHidden = false;
    }, error => {
      console.log('Error', error);
    });
  }


}
