<br/>
<br/>
<br/>
<br/>
<br/>
<section>
    <div class="container">
        <div class="row">
          <div class="col-sm">
              <h3>POWERWASHING SERVICES</h3>
              <br/>
               <p>From patios to parking-lots, and everything in between, we have the know-how and the equipment to power-wash even the grimiest of surfaces! Power-washing uses high-pressure water with carefully applied cleaning solutions to blast away years of grime! Power-washing is a cost-effective method to spruce up the outside of your property. </p>
               <br/>
               <p> Our services will be catered to your facility and its needs. Get in touch with us now for a quote!</p>
              <a class="btn btn-primary" [routerLink]="['/request']">Request a quote</a>
              <p></p>
              <br/>
          </div>
          <div class="col-sm">
              <img class="d-inline" id="side" src="./assets/images/pressurewash.jpg">
          </div>
        </div>
  </div>
</section>
