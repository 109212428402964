import { Component, OnInit } from '@angular/core';
import { PostService } from 'src/app/post.service';
import { Post } from 'src/app/post';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  posts: Post[];
  error = '';
  success = '';
  page = 1;
  pageSize = 3;
  collectionSize;

  constructor( private postService: PostService) { }

  ngOnInit() {
    this.getPosts();
  }
  
  getPosts(): void {
    this.postService.getAll().subscribe(
      (res: Post[]) => {
        this.posts = res;
        this.collectionSize = res.length;
      },
      (err) => {
        this.error = err;
      }
    );
}

}
