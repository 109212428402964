<br/>
<br/>
<br/>
<br/>
<br/>
<section>
    <div class="container">
        <div class="row">
          <div class="col-sm">
              <h3>Hotel Cleaning & Maintenance</h3>
              <br/>
               <br/>
               <p>Here at Wunderbar, we have extensive experience and knowledge when it comes to the upkeep of your hotel.</p>
               <p>Our list of clients includes but is not limited to King Blue Hotel Toronto, Avid, Sandman (Mississauga and the Ottawa locations) and many more.</p>
               <p>We have the equipment, the people, and the knowledge to tackle any of your needs, from taking over building maintenance to finding you the right supervisor, and everything in between-we are confident that we can assist you.</p>
              <br/>
              <a class="btn btn-primary" [routerLink]="['/request']">Request a quote</a>
              <br/><br/>
          </div>
          <div class="col-sm">
              <img class="d-inline" id="side" src="./assets/images/hotel.jpg">
              <br/>
          </div>
        </div>
  </div>
</section>
