<br/>
<br/>
<br/>
<br/>
<br/>
<section>
    <div class="container">
        <div class="row">
          <div class="col-sm">
              <h3>Building Cleaning & Maintenance</h3>
              <br/>
              <p>At Wunderbar, we make it our mission to ensure that your building is spotless so that you can spend less time worrying about the cleanliness of your building, and more time on the things that matter.</p>
              <p>With a custom tailored cleaning package designed to fit your needs, we can clean your building according to your schedule and needs.</p>
              <p>We have the expertise to ensure that your building is both presentable and sanitary, removing worries from your employees, tenants and/or customers!</p>
              <p>The services we offer includes, but is not limited to:</p>
               <br/>
               <ul class="list-group list-group-flush">
                  <li class="list-group-item">Floor care</li>
                  <li class="list-group-item">Waste management</li>
                  <li class="list-group-item">Vacuuming</li>
                  <li class="list-group-item">Dusting</li>
                  <li class="list-group-item">Restroom cleaning and restock</li>
                  <li class="list-group-item">Breakroom cleaning</li>
                  <li class="list-group-item">Common-room cleaning (laundries, mailrooms, etc)</li>
                  <li class="list-group-item">Special Events Cleaning</li>
                  <li class="list-group-item">Parking lot Cleaning</li>
                  <li class="list-group-item">Seasonal Cleaning</li>
              </ul>
              <br/>
          </div>
          <div class="col-sm">
              <img class="d-inline" id="side" src="./assets/images/building.jpg">
              <br/><br/><br/>
              <img class="d-inline" id="side" src="./assets/images/building2.jpg">
              <p> Our services will be catered to your facility and its needs. Get in touch with us now for a quote!</p>
              <a class="btn btn-primary" [routerLink]="['/request']">Request a quote</a>
              <p></p>

          </div>
        </div>
  </div>
</section>
