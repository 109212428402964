import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Mail } from './mail.model';

@Injectable({
providedIn: 'root'
})
export class ConnectionService {

// tslint:disable-next-line: no-inferrable-types
url: string = 'https://wunderbarmaintenance.com:443/backend';
//url: string = 'localhost/backend';
constructor(private http: HttpClient) { }

  sendMessage(mail: Mail) {
    return this.http.post(`mail.php`,
    JSON.stringify(mail),
    { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text' });
  }
}
